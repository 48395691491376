import React from 'react';

import { Container } from '../../components/home/v2/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import BeTilliste from '../../components/BeTilliste';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';

class BeTillistePage extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    return (
      <Layout routeSlug="BeTillistePage">
        <Container>
          <MenuBar />
          <BeTilliste />
          <Footer />
        </Container>
      </Layout>
    );
  }
}

export default BeTillistePage;
